import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  GooglePlusIcon,
  EmailIcon
} from 'react-share';

const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  &.bottom {
    margin-top: 3rem;
    border-top: 1px double ${props => props.theme.colors.global.border.light};
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const ShareTitle = styled.p`
  font-family: ${props => props.theme.fonts.header};
  margin-bottom: 0.15em;
`;

const ShareLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.header};
  div {
    margin-left: 0.5em;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    svg {
      path {
        transition: all 300ms ease-in-out;
      }
    }
    &:hover {
      svg {
        path {
          fill: ${props => props.theme.colors.global.primary} !important;
        }
      }
    }
  }
`;

const StoryShare = ({ isBottom, title, slug, featuredImage }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulCompanyInformation {
            websiteUrl
          }
        }
      `}
      render={data => {
        const shareURl =
          data.contentfulCompanyInformation.websiteUrl + '/story/' + slug;
        const media = featuredImage.fluid.src;
        return (
          <Wrapper className={isBottom ? 'bottom' : ''}>
            <ShareTitle>Share this story:</ShareTitle>
            <ShareLinks>
              <FacebookShareButton
                url={shareURl}
                quote={`${title} - Mountain Whispers`}
              >
                <FacebookIcon
                  size={40}
                  round
                  logoFillColor="#ffffff"
                  iconBgStyle={{ fill: 'none' }}
                />
              </FacebookShareButton>
              <TwitterShareButton url={shareURl}>
                <TwitterIcon
                  size={40}
                  round
                  logoFillColor="#ffffff"
                  iconBgStyle={{ fill: 'none' }}
                />
              </TwitterShareButton>
              <PinterestShareButton url={shareURl} media={media}>
                <PinterestIcon
                  size={40}
                  round
                  logoFillColor="#ffffff"
                  iconBgStyle={{ fill: 'none' }}
                />
              </PinterestShareButton>
              <GooglePlusShareButton url={shareURl}>
                <GooglePlusIcon
                  size={40}
                  round
                  logoFillColor="#ffffff"
                  iconBgStyle={{ fill: 'none' }}
                />
              </GooglePlusShareButton>
              <EmailShareButton
                url={shareURl}
                subject={`I just read this story by Mountain Whispers - ${title}`}
                body={shareURl}
              >
                <EmailIcon
                  size={40}
                  round
                  logoFillColor="#ffffff"
                  iconBgStyle={{ fill: 'none' }}
                />
              </EmailShareButton>
            </ShareLinks>
          </Wrapper>
        );
      }}
    />
  );
};

export default StoryShare;
