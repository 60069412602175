import styled from 'styled-components';

export const FullHeight = styled.section`
  height: 100vh;
  max-height: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
  text-align: center;
  @media (max-width: ${props => props.theme.responsive.small}) {
    height: 50vh;
    min-height: 24em;
    margin-bottom: 3em;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(11, 14, 30, 0.7) 0%,
      rgba(11, 14, 30, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b30b0e1e', endColorstr='#000b0e1e',GradientType=0 );
  }
`;
