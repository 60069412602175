import styled from 'styled-components';

const StoryWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.global.border.light};
  min-height: 50vh;
  padding: 4em;
  @media (max-width: ${props => props.theme.responsive.small}) {
    border: 0;
    padding: 3em 2em;
  }
`;

export default StoryWrapper;
