import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: justify;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
    color: ${props => props.theme.colors.global.base.light};
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  a {
    color: ${props => props.theme.colors.global.base.light};
    &:hover {
      color: ${props => props.theme.colors.global.primary};
    }
  }
  img {
    width: 100%;
    display: block;
  }
  ul {
    li {
      &:before {
        content: '✓';
        position: absolute;
        left: 0;
        color: ${props => props.theme.colors.global.primary};
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  blockquote {
    text-align: center;
    margin-top: -15px;
    p {
      font-family: ${props => props.theme.fonts.header} !important;
      font-style: italic;
      a {
        color: ${props => props.theme.colors.global.base.grey};
        &:hover {
          color: ${props => props.theme.colors.global.primary};
        }
      }
    }
  }
  strong {
    color: ${props => props.theme.colors.global.base.light};
  }
`;

const StoryTitle = ({ text }) => (
  <Wrapper
    dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
  />
);

export default StoryTitle;
