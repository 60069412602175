import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: -2em auto 0;
  width: 100%;
  max-width: 100%;
  padding: 1.25em 1.5em 2em;
  a {
    background: ${props => props.theme.colors.global.base.dark};
    color: white;
    margin: 0.5em;
    padding: 0.5em 1em;
    border-radius: 2px;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      background: ${props => props.theme.colors.global.primary};
    }
    &.disabled {
      opacity: 0.1;
      pointer-events: none;
      &:hover {
        background: ${props => props.theme.colors.global.base.dark};
      }
    }
  }
`;

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`;

const NextLink = styled(Link)`
  margin-left: auto;
  order: 3;
`;

const PageIndicator = styled.span`
  text-align: center;
  background: ${props => props.theme.colors.global.base.dark};
  color: white;
  margin: 0.5em;
  padding: 0.5em 1em;
  border-radius: 2px;
  order: 2;
  opacity: 0.7;
  &.disabled {
    opacity: 0.1;
  }
`;

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { context } = this.props;
    const { numPages, currentPage, slug } = context;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const isNotPaginated = isFirst && isLast;

    const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1;
    const nextPageNum = currentPage + 1;

    const pathPrefix =
      typeof slug === 'string' ? `/stories/${slug}` : '/stories';
    const prevPageLink = isFirst ? null : `${pathPrefix}/${prevPageNum}/`;
    const nextPageLink = isLast ? null : `${pathPrefix}/${nextPageNum}/`;
    return (
      <Wrapper>
        <PreviousLink
          className={isFirst ? 'disabled' : ''}
          to={prevPageLink !== null ? prevPageLink : '/'}
        >
          &#8592;
        </PreviousLink>
        <PageIndicator className={isNotPaginated ? 'disabled' : ''}>
          Page {currentPage}&nbsp;of&nbsp;{numPages}
        </PageIndicator>
        <NextLink
          className={isLast ? 'disabled' : ''}
          to={nextPageLink !== null ? nextPageLink : '/'}
        >
          &#8594;
        </NextLink>
      </Wrapper>
    );
  }
}

export default Pagination;
