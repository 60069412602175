import React from 'react';

// Components
import { RelatedWrapper, RelatedTitle, RelatedSlider } from '.';
import Container from '../../../common/container';
import CappedInner from '../../../common/cappedInner';

const Related = ({ category, relatedData }) => (
  <RelatedWrapper>
    <Container>
      <CappedInner>
        <RelatedTitle category={category} />
        <RelatedSlider relatedData={relatedData} />
      </CappedInner>
    </Container>
  </RelatedWrapper>
);

export default Related;
