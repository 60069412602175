import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.h1`
  color: ${props => props.theme.colors.global.base.light};
`;

const StoryTitle = ({ title }) => <Wrapper>{title}</Wrapper>;

export default StoryTitle;
