import styled from 'styled-components';

const Container = styled.div`
  padding-left: 2.5em;
  padding-right: 2.5em;
  max-width: 83.75em;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding-left: 1.875em;
    padding-right: 1.875em;
  }
`;

export default Container;
