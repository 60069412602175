import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

// Components
import { NewsletterInput } from '.';

const Wrapper = styled.div`
  margin: 0;
  width: 100%;
  min-height: 25em;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-shadow: 0px 2px 4px ${props => props.theme.colors.global.shadow.dark};
  &:before {
    content: '';
    background: ${props => props.theme.colors.global.background.hover};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.3s ease-out;
  }
  &:hover {
    &:after {
      opacity: 0.7;
    }
    img {
      transform: scale(1.05);
    }
  }
`;

const NewsletterInner = styled.div`
  position: relative;
  z-index: 3;
  color: #fff;
  text-align: center;
  width: 85%;
  padding: 1em 1em 3em 1em;
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100% !important;
  }
`;

const Diamond = styled.span`
  display: block;
  margin: 1rem auto;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  background: ${props => props.theme.colors.global.primary};
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: none;
  }
`;

const NewsletterImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${props => props.theme.colors.global.background.dark};
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) !important;
  }
`;

const Newsletter = ({ featuredImage }) => (
  <Wrapper>
    <NewsletterInner>
      <h2>Sign up to our Newsletter</h2>
      <Diamond />
      <p>
        Sign up to our newsletter to keep up to date with our news and exclusive
        offers at Mountain Whispers.
      </p>
      <NewsletterInput />
    </NewsletterInner>
    <NewsletterImage fluid={featuredImage.fluid} title={featuredImage.title} />
  </Wrapper>
);

export default Newsletter;
