import styled from 'styled-components';

const RelatedWrapper = styled.div`
  position: relative;
  background: ${props => props.theme.colors.global.background.dark};
  z-index: 1;
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

export default RelatedWrapper;
