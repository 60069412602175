import React from 'react';
import { Location } from '@reach/router';

import {
  StoryWrapper,
  BackToStories,
  StoryTitle,
  StoryShare,
  StoryDate,
  StoryBody,
  Newsletter
} from './files';

const StoryIndex = ({
  title,
  slug,
  featuredImage,
  datePosted,
  categories,
  text
}) => (
  <StoryWrapper>
    <Location>
      {({ location }) => <BackToStories location={location} />}
    </Location>
    <StoryTitle title={title} />
    <StoryDate datePosted={datePosted} categories={categories} />
    <StoryShare title={title} slug={slug} featuredImage={featuredImage} />
    <StoryBody text={text} />
    <StoryShare
      isBottom
      title={title}
      slug={slug}
      featuredImage={featuredImage}
    />
    <Newsletter featuredImage={featuredImage} />
  </StoryWrapper>
);

export default StoryIndex;
