import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoContent';
import StoryIndex from '../components/templates/story';
import { FullHeight } from '../components/common/fullHeight';
import { StoryImage, Related } from '../components/templates/story/files';
import { ContentContainer } from '../components/templates/storyTags/files';

// Context
import ContextConsumer from '../components/common/contextProvider';

const StoryTemplate = ({ data, pageContext }) => (
  <ContextConsumer>
    {({ context }) => (
      <Content data={data} pageContext={pageContext} context={context} />
    )}
  </ContextConsumer>
);

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, pageContext } = this.props;
    const {
      id,
      title,
      slug,
      text,
      featuredImage,
      categories,
      datePosted
    } = data.contentfulStories;
    const seoContent = {
      id,
      menuTitle: title,
      pageTitle: title,
      slug,
      description: text.childMarkdownRemark.excerpt,
      featuredImage
    };
    const relatedData = data.allContentfulStoryTags.edges;
    const category = pageContext.category;
    return (
      <Fragment>
        <SeoDetails seoContent={seoContent} />
        <FullHeight>
          <StoryImage featuredImage={featuredImage} />
        </FullHeight>
        <ContentContainer className="article">
          <StoryIndex
            id={id}
            title={title}
            slug={slug}
            text={text}
            featuredImage={featuredImage}
            categories={categories}
            datePosted={datePosted}
          />
        </ContentContainer>
        <Related relatedData={relatedData} category={category} />
      </Fragment>
    );
  }
}

export const query = graphql`
  query($slug: String!, $category: String!) {
    contentfulStories(slug: { eq: $slug }) {
      id
      title
      slug
      text {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 160)
        }
      }
      featuredImage {
        id
        title
        description
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      categories {
        id
        seoContent {
          menuTitle
          slug
        }
      }
      author
      datePosted(formatString: "MMMM DD, YYYY")
    }
    allContentfulStoryTags(
      filter: { seoContent: { slug: { eq: $category } } }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          seoContent {
            menuTitle
            slug
          }
          stories {
            id
            title
            slug
            featuredImage {
              title
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            categories {
              id
              seoContent {
                menuTitle
                slug
              }
            }
            author
            datePosted
          }
        }
      }
    }
  }
`;

export default StoryTemplate;
