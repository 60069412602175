import styled from 'styled-components';

const CappedInner = styled.div`
  margin-left: 8.333%;
  margin-right: 8.333%;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export default CappedInner;
