import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StoryImage = ({ featuredImage }) => (
  <Wrapper>
    <Img fluid={featuredImage.fluid} alt={featuredImage.description} />
  </Wrapper>
);

export default StoryImage;
