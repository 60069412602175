import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  max-width: 38em;
  margin: 0 auto 2rem auto;
  h2 {
    color: ${props => props.theme.colors.global.base.light};
    text-transform: capitalize;
  }
`;

const RelatedTitle = ({ category }) => {
  const slugTitle = category.replace(/-/g, ' ');

  return (
    <Wrapper>
      <h2>Related {slugTitle} Stories</h2>
    </Wrapper>
  );
};

export default RelatedTitle;
