import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.h4`
  color: ${props => props.theme.colors.global.base.grey};
  a {
    color: ${props => props.theme.colors.global.base.grey};
    &:hover {
      color: ${props => props.theme.colors.global.primary};
    }
  }
`;

const StoryDate = ({ datePosted, categories }) => (
  <Wrapper>
    {datePosted}
    {categories.map(i => (
      <React.Fragment key={i.id}>
        <span>&nbsp;|&nbsp;</span>
        <Link to={`/stories/${i.seoContent.slug}`}>
          {i.seoContent.menuTitle}
        </Link>
      </React.Fragment>
    ))}
  </Wrapper>
);

export default StoryDate;
