import React from 'react';
import styled from 'styled-components';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

// Icons
import { ArrowRightIcon } from '../../../../assets/images/icons';

const FormWrapper = styled.div`
  position: relative;
  max-width: 22em;
  margin: 0 auto;
  input {
    border-bottom: 1px solid ${props => props.theme.colors.global.base.light};
    margin: 0;
    padding-left: 0.5em;
    color: ${props => props.theme.colors.global.base.light};
    &::placeholder {
      color: ${props => props.theme.colors.global.base.light};
    }
  }
  button {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 2em;
    padding: 0;
    right: 0;
    border: 0;
    min-width: 0;
    z-index: 3;
    svg {
      fill: ${props => props.theme.colors.global.primary};
      width: 15px;
      height: 15px;
    }
    &:hover {
      transform: translateX(5px);
    }
  }
`;

const Message = styled.div`
  text-shadow: none;
  color: ${props => props.theme.colors.white};
  position: absolute;
  border-radius: 5px;
  right: 20px;
  left: 20px;
  padding: 1em 2em;
  text-transform: initial;
  box-shadow: 0 10px 6px -6px ${props => props.theme.colors.global.background.dark};
  max-width: 410px;
  margin: -100px auto 0;
  font-size: 13px;
  font-weight: 700;
  a {
    color: ${props => props.theme.colors.global.base.light};
    text-decoration: underline;
  }
`;

const url =
  'https://mountainwhispers.us5.list-manage.com/subscribe/post?u=ee75003cd7b6a3797efe89209&id=561dce9d71';

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value
    });
  return (
    <React.Fragment>
      {status === 'sending' && (
        <Message style={{ background: '#e7b679' }}>sending...</Message>
      )}
      {status === 'error' && (
        <Message
          style={{ background: '#f44336' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <Message
          style={{ background: '#4da781' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="Email Address"
      />
      <div className="submit">
        <button type="submit" onClick={submit}>
          {ArrowRightIcon}
        </button>
      </div>
    </React.Fragment>
  );
};

class NewsletterInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FormWrapper>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </FormWrapper>
    );
  }
}

export default NewsletterInput;
