import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import styled from 'styled-components';

// Components
import { Link } from '../../../common/linkWithPrevUrl';

// Icons
import {
  ScrollRightIcon,
  ScrollLeftIcon,
  ArrowStoriesIcon
} from '../../../../assets/images/icons';

const Wrapper = styled.div`
  @media (max-width: ${props => props.theme.responsive.small}) {
    margin-left: -1.875em;
    margin-right: -1.875em;
  }
  .slick-slider {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .slick-list {
      width: 100%;
      .slick-track {
        width: 100%;
        &::before,
        &::after {
          content: '';
          display: table;
        }
        .slick-slide {
          display: flex;
          height: auto;
          > div {
            margin: 0.5em;
            width: 100%;
          }
        }
      }
    }
  }
`;

const Post = styled(Link)`
  margin: 0.5em;
  flex-grow: unset;
  width: 100%;
  min-height: 18em;
  flex-grow: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: calc(100% - 0.5em);
    margin: 0 auto;
    min-height: 20em;
  }
  &:before {
    content: '';
    background: ${props => props.theme.colors.global.background.hover};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &:hover {
    &:before {
      opacity: 0.7;
    }
    .story-button {
      border: 1px solid ${props => props.theme.colors.global.primary};
      background: ${props => props.theme.colors.global.primary};
    }
    img {
      transform: scale(1.05);
    }
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 3;
  color: ${props => props.theme.colors.global.base.light};
  text-align: center;
  width: 85%;
  padding: 1em 1em 3em 1em;
`;

const Title = styled.h4`
  margin-bottom: 0;
  font-size: 1.5em;
  text-shadow: 0px 2px 4px ${props => props.theme.colors.global.shadow.dark};
  @media (max-width: ${props => props.theme.responsive.small}) {
    margin: 0;
    font-size: 1.5em;
    line-height: 1.289473684210526;
  }
`;

const Categories = styled.h6`
  font-size: 0.9rem;
  margin: 0.5em 0;
  text-shadow: 0px 2px 4px ${props => props.theme.colors.global.shadow.dark};
  .divider {
    &:last-child {
      display: none;
    }
  }
`;

const Button = styled.span`
  min-width: 0;
  position: absolute;
  bottom: 1.5em;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  color: ${props => props.theme.colors.global.base.light};
  background: none;
  padding: 0.5em;
  transition: all 0.3s ease-out;
  border: 1px solid ${props => props.theme.colors.global.base.light};
  svg {
    width: 0.75em;
    height: 0.75em;
    display: block;
    polygon {
      fill: ${props => props.theme.colors.global.background.light};
    }
  }
`;

const StoryImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) !important;
  }
`;

const ArrowLeft = styled.div`
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  transform: none;
  width: 8.333%;
  z-index: 3;
  cursor: pointer;
  padding: 0.5em;
  border: 0;
  min-width: 0;
  right: 100%;
  left: auto;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    left: 0;
    right: auto;
  }
  button {
    left: 0;
    transform: translate(50%, -50%);
    position: absolute;
    border: 0;
    min-width: 0;
    top: 50%;
    z-index: 1;
    transition: all 0.3s ease !important;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      left: -80px;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      left: 0;
    }
    svg {
      path {
        fill: ${props => props.theme.colors.global.base.light};
      }
    }
    &:hover {
      svg {
        path {
          fill: ${props => props.theme.colors.global.primary};
        }
      }
    }
  }
`;

const ArrowRight = styled.div`
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  transform: none;
  width: 8.333%;
  z-index: 3;
  cursor: pointer;
  padding: 0.5em;
  border: 0;
  min-width: 0;
  left: 100%;
  right: auto;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    right: 0;
    left: auto;
  }
  button {
    right: 50px;
    transform: translate(50%, -50%);
    position: absolute;
    border: 0;
    min-width: 0;
    top: 50%;
    z-index: 1;
    transition: all 0.3s ease !important;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      right: -20px;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      right: 60px;
    }
    svg {
      polygon {
        fill: ${props => props.theme.colors.global.base.light};
      }
    }
    &:hover {
      svg {
        polygon {
          fill: ${props => props.theme.colors.global.primary};
        }
      }
    }
  }
`;

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <ArrowLeft>
      <button onClick={onClick} type="button">
        {ScrollLeftIcon}
      </button>
    </ArrowLeft>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <ArrowRight>
      <button onClick={onClick} type="button">
        {ScrollRightIcon}
      </button>
    </ArrowRight>
  );
}

const RelatedSlider = ({ relatedData }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: true
        }
      }
    ]
  };
  return (
    <Wrapper>
      {relatedData.map(({ node }) => (
        <Slider key={node.id} {...settings}>
          {node.stories.map(related => (
            <Post key={related.id} to={`/story/${related.slug}/`}>
              <Inner>
                <Title>{related.title}</Title>
                <Categories>
                  {related.categories.map(i => (
                    <React.Fragment key={i.id}>
                      {i.seoContent.menuTitle}
                      <span className="divider">&nbsp;|&nbsp;</span>
                    </React.Fragment>
                  ))}
                </Categories>
              </Inner>
              <Button className="story-button">{ArrowStoriesIcon}</Button>
              <StoryImage
                fluid={related.featuredImage.fluid}
                backgroundColor="#111111"
              />
            </Post>
          ))}
        </Slider>
      ))}
    </Wrapper>
  );
};
export default RelatedSlider;
