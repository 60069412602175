import React from 'react';
import styled from 'styled-components';

// Components
import { Link } from '../../../common/linkWithPrevUrl';

// Icons
import { ArrowRightIcon } from '../../../../assets/images/icons';

const Wrapper = styled.p`
  a {
    font-family: ${props => props.theme.fonts.header};
    text-transform: uppercase;
    font-size: 1.125em;
    color: ${props => props.theme.colors.global.base.light};
    position: relative;
    padding-left: 1.5em;
    transition: all 300ms ease-in-out;
    span {
      margin-right: 10px;
      margin-left: -25px;
      svg {
        transform: rotate(180deg);
        height: 15px;
        width: 15px;
        fill: ${props => props.theme.colors.global.primary};
        transition: all 300ms ease-in-out;
      }
    }
    &:hover {
      color: ${props => props.theme.colors.global.primary};
      span {
        svg {
          transform: translateX(-5px) rotate(180deg);
        }
      }
    }
  }
`;

const BackToStories = ({ location }) => {
  const PrevLocation = !location.state
    ? '/stories/'
    : location.state.prevUrl.replace(location.origin, '');
  return (
    <Wrapper>
      <Link to={PrevLocation}>
        <span>{ArrowRightIcon}</span>
        {location.state === null || undefined
          ? 'Back to stories'
          : 'Back to previous'}
      </Link>
    </Wrapper>
  );
};

export default BackToStories;
